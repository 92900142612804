import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='footer footer-bg'>
      <div className='footer-logo-wrapper'>
        <Link to={"/"} className='footer-logo'>
          <img className="img-cover" src="/logo.png" alt="logo"/>
        </Link>
      </div>
      <div className='footer-content'>
        <div className='footer-content-item'>
          <h4 className='big-text bold-text black-text pad-b-m'>Liens utiles</h4>
          <div className='grid-1-2-extra'>
            <div className='flex-col-start'>
              <ul className='footer-nav'>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/a-propos"} className='footer-nav-link footer-nav-link-od'>
                    A propos
                  </NavLink>
                </li>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/formules"} className='footer-nav-link footer-nav-link-od'>
                    Nos formules
                  </NavLink>
                </li>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/galerie"} className='footer-nav-link footer-nav-link-od'>
                    Galerie photo
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className='flex-col-start'>
              <ul className='footer-nav'>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/articles"} className='footer-nav-link footer-nav-link-od'>
                    Actualitées
                  </NavLink>
                </li>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/mentions-legals"} className='footer-nav-link footer-nav-link-od'>
                    Mentions legals
                  </NavLink>
                </li>
                <li className='footer-nav-item'>
                  <NavLink activeclassname="active" to={"/politique-confidentialites"} className='footer-nav-link footer-nav-link-od'>
                    Politique de confidentialités
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='footer-content-item'>
          <h4 className='big-text bold-text black-text pad-b-m'>Nous suivre</h4>
          <div className='grid-r-2-3'>
            <div className='grid-item'>
              <Link to={"https://www.instagram.com/dj_tdy_pro_events/"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                </svg>
              </Link>
            </div>
            <div className='grid-item'>
              <Link to={"https://www.facebook.com/dj.tdy"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                </svg>
              </Link>
            </div>
            {/* <div className='grid-item'>
              <Link to={"https://www.linkedin.com"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
                </svg>
              </Link>
            </div>
            <div className='grid-item'>
              <Link to={"http://www.twitter.com"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM351.3 199.3v0c0 86.7-66 186.6-186.6 186.6c-37.2 0-71.7-10.8-100.7-29.4c5.3 .6 10.4 .8 15.8 .8c30.7 0 58.9-10.4 81.4-28c-28.8-.6-53-19.5-61.3-45.5c10.1 1.5 19.2 1.5 29.6-1.2c-30-6.1-52.5-32.5-52.5-64.4v-.8c8.7 4.9 18.9 7.9 29.6 8.3c-9-6-16.4-14.1-21.5-23.6s-7.8-20.2-7.7-31c0-12.2 3.2-23.4 8.9-33.1c32.3 39.8 80.8 65.8 135.2 68.6c-9.3-44.5 24-80.6 64-80.6c18.9 0 35.9 7.9 47.9 20.7c14.8-2.8 29-8.3 41.6-15.8c-4.9 15.2-15.2 28-28.8 36.1c13.2-1.4 26-5.1 37.8-10.2c-8.9 13.1-20.1 24.7-32.9 34c.2 2.8 .2 5.7 .2 8.5z"/>
                </svg>
              </Link>
            </div> */}
            <div className='grid-item'>
              <Link to={"mailto:djtdyproevent@gmail.com"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                </svg>
              </Link>
            </div>
            <div className='grid-item'>
              <Link to={"tel:0685766710"} target='_blank' className='icon-medium bg-icon-black'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-bottom pad-t-xl'>
        <p className='small-text regular-text black-text'>Copyright &copy; {new Date().getFullYear()} - DJ-TDY PROEVENTS</p>
        <p className='small-text regular-text black-text'>Made by Fujyn</p>
      </div>

    </div>
  )
}

export default Footer