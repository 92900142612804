import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import axiosProvider from "../../core/axios";
import Projector from "../../components/projector";
import Modal from "../../components/modal";
import Metadata from "../../components/metadata";
import { fadeInBottomAnimationVariants, fadeInLeftAnimationVariants, imageZoomVariants } from "../../components/motionVariant";


const Home = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);
    const [newsletterError, setNewsletterError] = useState("");
    const [metadata] = useState({
        title : "Accueil - L'univers de Dj-Tdy",
        description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        generator : "Made by fujyn",
        keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
        canonical : "http://www.bestexemple.com/canonical",
        og_locale : "en_GB",
        og_locale_alternate : "fr_fr",
        og_type : "article",
        og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        og_url : "https://www.thisisatest.com",
        og_image_url : "https://www.bestexemple.com/exempleimage.png",
        og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
        article_section : "Web development",
        article_publisher : "Test author",
        article_published_time : "2023-01-07T02:10:58.678Z",
        article_modified_time : "2023-01-07T02:10:58.678Z",
        twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
        twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
        twitter_site : "@testuser",
        twitter_card : "summuary_test",
        twitter_image : "https://www.bestexemple.com/exempleimage.png",
        twitter_creator : "@testuser",
        twitter_cta : "En savoir plus",
        twitter_label_1 : "Ecrit par",
        twitter_data_1 : "DJ-Tdy Team",
        twitter_label_2 : "Temps de lecture",
        twitter_data_2 : "10 minutes",
    })
    const [newsletterModal, setNewsletterModal] = useState({
        display : false,
        variation : "ok",
        title: "",
        content: "",
        subContent: "",
        type: ""
    })

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/core/home").then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    const validateEmail = (email) => {
        if(email.length > 0 && email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)){
            return true
        }
        return false
    }

    const newsletterRegistration = (e) => {
        e.preventDefault();
        setNewsletterError("")
        const input = document.getElementById("newsletter-input")
        const email = e.target.elements.newsletter_registration.value
        if(validateEmail(email)){
            axiosProvider.post('/newsletter/create/', {
                "email" : email
            }).then((response) => {
                setNewsletterModal({
                    display : true,
                    variation : "ok",
                    title: "Inscription à la newsletter",
                    content: "L'inscription a la newsletter de dj-Tdy c'est dérouler avec succès.",
                    subContent: "Vous allez recevoir un mail à chaque nouveautés que nous proposerons sur se site.",
                    type: "success"
                })
            }).catch((error) => {
                setNewsletterModal({
                    display : true,
                    variation : "ok",
                    title: "Inscription à la newsletter",
                    content: "Une erreur est survenue lors de l'inscription à la newsletter de dj-Tdy.",
                    subContent: error.response.status === 400 ? "Votre adresse mail est déjà enregistrer dans notre liste." : "Une erreur serveur est survenue, Veuillez réessayer ultèrieurement.",
                    type: "error"
                })
            })
            input.value = ""
        }else{
            setNewsletterError("Merci d'entrer une adresse mail valide.")
        }
    };
    
    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={metadata} type="page" />
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-ban bg-white over-hidden" ref={scrollRef}>
                        <div className="ban-img-wrapper">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 90, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content over-hidden">
                            <div className="gutter-large">
                                <div className="grid-1-2">
                                    <div className="grid-item">
                                        <div className="full-width z-index-front">
                                            <h1 className="big-text bold-text white-text pad-b-xl">Faite de votre événement une expérience mémorable</h1>
                                            <Link to="/demande" className="btn btn-bordered btn-large btn-great">Animer vos évènements</Link>
                                        </div>
                                    </div>
                                    <div className="grid-item-r">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Projector spin={'left'} color={'current'} />
                        <Projector spin={'right'} color={'sand'} />
                        <Projector spin={'left'} color={'great'} />
                        <Projector spin={'right'} color={'danger'} />
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Resume bubbles --------------------------------*/}
                    <div className="container-w-medium bg-white">
                        <div className="spacer"></div>
                        <div className="gutter">
                            <div className="grid-2-4 pad-b-xl pad-t-xl">
                                <div className="grid-item">
                                    <div className="mini-card">
                                        <p className="large-text bold-text pad-b-s">+ 10 ans</p>
                                        <p className="medium-text regular-text">d'experience</p>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="mini-card">
                                        <p className="large-text bold-text pad-b-s">+ 200</p>
                                        <p className="medium-text regular-text">Mix Réaliser</p>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="mini-card">
                                        <p className="large-text bold-text pad-b-s">99 %</p>
                                        <p className="medium-text regular-text">De clients satisfaits</p>
                                    </div>
                                </div>
                                <div className="grid-item">
                                    <div className="mini-card">
                                        <p className="large-text bold-text pad-b-s">+ 20</p>
                                        <p className="medium-text regular-text">Style de musiques</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- About --------------------------------*/}
                    <div className="container bg-white">
                        <div className="grid-1-2-extra pad-b-xl pad-t-xl">
                            <div className="grid-item">
                                <div className="gutter full-width">
                                    <motion.div variants={fadeInLeftAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.7, delay:0.6}} className="card-img-display">
                                        <img className="img-cover" src="/images/djtdy-smooth-pres.jpg" alt="DJ-TDY" />
                                    </motion.div>
                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="gutter-text full-width">
                                    <div className="flex-col-start full-width pad-b-xl">
                                        <div className="flex-row-start">
                                            <h2 className="big-text bold-text black-text pad-b-m">DJ-TDY en quelques mots</h2>
                                        </div>
                                        <p className="large-text regular-text black-text pad-b-s">DJ et annimateur depuis de nombreuses années. J'assure l'annimation de tous types d'évènements (mariages, anniversaires, baptêmes, soirées particulières avec des thématiques diverses, karaoké).</p>
                                        <p className="large-text regular-text black-text pad-b-xl">Mon objectif premier c'est votre satisfaction. Je me donne ainsi les moyens de faire de votre évènement un moment mémorable à votre convenance et à votre image.</p>
                                        <div className="flex-full-center">
                                            <Link to="/a-propos" className="btn btn-bordered btn-large btn-great">En savoir plus</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- Formula --------------------------------*/}
                    <div className="container bg-white">
                        <div className="background-img">
                            <img className="img-cover" src="/images/platine-3.jpg" alt="Platine" />
                        </div>
                        <div className="inner-abs-container">
                            <div className="spacer"></div>
                            <div className="flex-col-center">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path
                                                d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text white-text pad-l-m">Nos formules</h2>
                                </div>
                            </div>
                            <div className="gutter">
                                <div className="fancy-card-wrapper">
                                    <div className="fancy-card-list">
                                        {data.formulas.map((formula, i) => {
                                            return (
                                                <div className="fancy-card" key={i}>
                                                    <Link to={`/formules/${formula.slug}`} className="fancy-card-head fancy-card-head-blue">
                                                        <p className="big-text bold-text white-text">{formula.title}</p>
                                                    </Link>
                                                    <div className="fancy-card-content">
                                                        <div className="flex-col-center">
                                                            <p className="big-text bold-text blue-text">{formula.pack}</p>
                                                        </div>
                                                        <div className="flex-col-start">
                                                            <ul className="fancy-item-list">
                                                                {formula.items.map((item, i) => {
                                                                    return (
                                                                        <li className="fancy-card-item" key={i}>
                                                                            <div className="circle-dot circle-blue"></div>
                                                                            <p className="large-text regular-text r-black-text pad-l-s">
                                                                            <b>{item.quantity}</b> {item.title}
                                                                            </p>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="fancy-card-footer pad-b-l">
                                                        <p className="medium-text regular-text r-black-text pad-b-s">à partir de</p>
                                                        <p className="big-text bold-text blue-text">{formula.price} €</p>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row-center pad-b-xl">
                                <Link to={"/formules"} className="btn btn-large btn-bordered btn-cream">Voir plus</Link>
                            </div>
                            <div className="spacer"></div>
                        </div>
                    </div>
                    {/*-------------------------------- Options Simples --------------------------------*/}
                    <div className="container-small bg-white">
                        <div className="spacer"></div>
                        <div className="flex-col-center">
                            <div className="flex-row-center pad-t-l pad-b-xl">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 590 512">
                                        <path d="M0 32C0 14.3 14.3 0 32 0H160c17.7 0 32 14.3 32 32V416c0 53-43 96-96 96s-96-43-96-96V32zM223.6 425.9c.3-3.3 .4-6.6 .4-9.9V154l75.4-75.4c12.5-12.5 32.8-12.5 45.3 0l90.5 90.5c12.5 12.5 12.5 32.8 0 45.3L223.6 425.9zM182.8 512l192-192H480c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H182.8zM128 64H64v64h64V64zM64 192v64h64V192H64zM96 440a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m">Les options simples</h2>
                            </div>
                        </div>
                        <motion.div variants={fadeInBottomAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.4, delay:0.4}} className="flex-col-center pad-b-xl">
                            <div className="fancy-small-card-wrapper">
                                <div className="fancy-small-card-list">
                                    {data.simple_options.map((simpleOption, i) => {
                                        return (
                                            <div className="fancy-small-card" key={i}>
                                                <div className="fancy-small-card-content">
                                                    <p className="medium-text bold-text r-black-text">{simpleOption.title}</p>
                                                </div>
                                                <div className="fancy-small-card-footer">
                                                    <p className="large-text bold-text white-text" >{simpleOption.price} €</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </motion.div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- Options Pack --------------------------------*/}
                    <div className="container-small bg-white">
                        <div className="flex-col-center">
                            <div className="flex-row-center pad-t-l pad-b-xl">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                        <path d="M58.9 42.1c3-6.1 9.6-9.6 16.3-8.7L320 64 564.8 33.4c6.7-.8 13.3 2.7 16.3 8.7l41.7 83.4c9 17.9-.6 39.6-19.8 45.1L439.6 217.3c-13.9 4-28.8-1.9-36.2-14.3L320 64 236.6 203c-7.4 12.4-22.3 18.3-36.2 14.3L37.1 170.6c-19.3-5.5-28.8-27.2-19.8-45.1L58.9 42.1zM321.1 128l54.9 91.4c14.9 24.8 44.6 36.6 72.5 28.6L576 211.6v167c0 22-15 41.2-36.4 46.6l-204.1 51c-10.2 2.6-20.9 2.6-31 0l-204.1-51C79 419.7 64 400.5 64 378.5v-167L191.6 248c27.8 8 57.6-3.8 72.5-28.6L318.9 128h2.2z"/>
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m">Les packs d'options</h2>
                            </div>
                        </div>
                        <motion.div variants={fadeInBottomAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.4, delay:0.4}} className="flex-col-center pad-b-xl">
                            <div className="fancy-card-simple-wrapper">
                            <div className="fancy-card-simple-list">
                                {data.option_packs.map((optionPack, i) => {
                                    return (
                                        <div className="fancy-card-simple-od" key={i}>
                                            <div className="fancy-card-simple-header">
                                                <div className="flex-col-center pad-b-xl">
                                                    <p className="big-text bold-text blue-text">{optionPack.title}</p>
                                                </div>
                                            </div>
                                            <div className="fancy-card-simple-content pad-b-xl">
                                                <div className="flex-col-start">
                                                    <ul className="fancy-item-list">
                                                        {optionPack.items.map((item, m) => {
                                                            return (
                                                                <li className="fancy-card-item" key={m}>
                                                                    <div className="circle-dot circle-blue"></div>
                                                                    <p className="large-text bold-text r-black-text pad-l-s pad-r-s">{item.quantity}</p>
                                                                    <p className="large-text regular-text r-black-text pad-l-s">{item.simple_option.title}</p>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="fancy-card-simple-footer pad-b-xl">
                                                <p className="big-text bold-text blue-text">590 €</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            </div>
                        </motion.div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- Galery --------------------------------*/}
                    <div className="container bg-od-white">
                        <div className="spacer"></div>
                        <div className="flex-col-center">
                            <div className="flex-row-center pad-t-l pad-b-xl">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                        <path d="M160 80H512c8.8 0 16 7.2 16 16V320c0 8.8-7.2 16-16 16H490.8L388.1 178.9c-4.4-6.8-12-10.9-20.1-10.9s-15.7 4.1-20.1 10.9l-52.2 79.8-12.4-16.9c-4.5-6.2-11.7-9.8-19.4-9.8s-14.8 3.6-19.4 9.8L175.6 336H160c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16zM96 96V320c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H160c-35.3 0-64 28.7-64 64zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V344c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120zm208 24a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m">Galerie photo</h2>
                            </div>
                        </div>
                        <motion.div variants={fadeInBottomAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.4, delay:0.6}}  className="flex-col-center">
                            <div className="gutter full-width">
                                <div className="grid-1-mixed">
                                    {data.galeries.map((image, i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <Link to="/galerie" className="card-img">
                                                    <img className="img-cover" src={image.media} alt={image.alt} />
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    {/*-------------------------------- News --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="flex-col-center">
                            <div className="flex-row-center pad-t-l pad-b-xl">
                                <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M96 96c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H80c-44.2 0-80-35.8-80-80V128c0-17.7 14.3-32 32-32s32 14.3 32 32V400c0 8.8 7.2 16 16 16s16-7.2 16-16V96zm64 24v80c0 13.3 10.7 24 24 24H296c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H184c-13.3 0-24 10.7-24 24zm208-8c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H384c-8.8 0-16 7.2-16 16zM160 304c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm0 96c0 8.8 7.2 16 16 16H432c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16z"/>
                                    </svg>
                                </div>
                                <h2 className="big-text bold-text black-text pad-l-m">Actualités</h2>
                            </div>
                        </div>
                        <div className="flex-col-center">
                            <div className="gutter">
                                <div className="grid-4">
                                    {data.news.map((article,i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <Link to={`/articles/${article.slug}`} className="mod-card">
                                                    <div className="mod-card-header">
                                                        <img className="img-cover" src={article.thumbnail} alt={article.title} />
                                                    </div>
                                                    <div className="mod-card-content">
                                                        <p className="large-text bold-text r-black-text pad-b-m">{article.title}</p>
                                                        <div className="grid-3-5 pad-b-m">
                                                            {article.categories ? article.categories.map((category,i) => {
                                                                return (
                                                                    <div className="grid-item-fit" key={i}>
                                                                        <span className="tag tag-small tag-great marg-r-s">{category.title}</span>
                                                                    </div>
                                                                )
                                                            }) : ""}
                                                        </div>
                                                        <p className="medium-text regular-text r-black-text pad-b-s">Lorem uoiiudsof poujsqd lkjsqjdsq i oiu odsif soiduze j lkdsj lsdjfjlkqsd j poids ipi piap aze almkq lskqmk qmksd kdposi. kqsd j poids ipi piap aze almkq lskqmk qmksd kdposi kqsd j poids ipi piap aze almkq lskqmk qmksd kdposi.</p>
                                                    </div>
                                                    <div className="mod-card-footer">
                                                        <p className="small-text bold-text r-black-text">09/05/2024 15:34</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="flex-row-center pad-t-xl pad-b-xl">
                                    <Link to={"/articles"} className="btn btn-large btn-bordered btn-cream">Voir plus</Link>
                                </div>
                            </div>  
                        </div>
                    </div>
                    {/*-------------------------------- Google Resume --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="gutter">
                            <div className="flex-col-center">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Nos avis</h2>
                                </div>
                            </div>
                            <div className="flex-col-center pad-b-xl">
                                <div className="flex-col-center">
                                    <span className="big-text regular-text black-text pad-b-m">5/5</span>
                                </div>
                                <div className="flex-row-center pad-b-xl">
                                    <div className="icon-small bg-icon-sand">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                        </svg>
                                    </div>
                                    <div className="icon-small bg-icon-sand pad-l-m">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                        </svg>
                                    </div>
                                    <div className="icon-small bg-icon-sand pad-l-m">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                        </svg>
                                    </div>
                                    <div className="icon-small bg-icon-sand pad-l-m">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                        </svg>
                                    </div>
                                    <div className="icon-small bg-icon-sand pad-l-m">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                        </svg>
                                    </div>
                                </div>
                                <motion.div variants={fadeInBottomAnimationVariants} initial={"initial"} whileInView={"animate"} transition={{duration:0.4, delay:0.4}} className="grid-4-r4">
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="feed-card feed-card-od">
                                            <div className="feed-card-header">
                                                <div className="flex-row-start">
                                                    <div className="shape shape-x-small shape-circle">
                                                        <img className="img-cover" src="/images/djtdy.jpg" alt="DJ-TDY" />
                                                    </div>
                                                    <div className="flex-col-start pad-l-m">
                                                        <p className="large-text bold-text black-text pad-b-s">Laurent Gina</p>
                                                        <div className="flex-row-center">
                                                            <div className="icon-x-small bg-icon-sand">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                            <div className="icon-x-small bg-icon-sand pad-l-s">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="feed-card-content">
                                                <p className="medium-text regular-text black-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sagittis massa blandit, venenatis ante in, suscipit est.</p>
                                            </div>
                                            <div className="feed-card-footer">
                                                <p className="small-text regular-text black-text">04/05/2024 04:45</p>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                        </div>
                        <div className="spacer"></div>
                    </div>
                    {/*-------------------------------- NewsLetter --------------------------------*/}
                    <div className="container-small bg-od-white">
                        <div className="shape-abs shape-medium shape-triangle-upper-left shape-corner-top-left shape-great"></div>
                        <div className="gutter h-70">
                            <div className="flex-col-center full-width full-height">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
                                    </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Newsletter</h2>
                                </div>
                                <div className="flex-col-center">
                                    <p className="large-text regular-text black-text center-text pad-b-xl">Inscrivez-vous à la newsletter pour rester informer des nouveautés et des évènements à venir.</p>
                                    <form className="quick-form" onSubmit={newsletterRegistration}>
                                        {newsletterError ? (
                                            <p className="medium-text bold-text red-text pad-b-m">{newsletterError}</p>
                                        ) : ""}
                                        <div className="quick-form-item pad-b-l">
                                            <input id="newsletter-input" className="input input-medium input-rounded input-border-current" name="newsletter_registration" placeholder="Entrer votre adresse mail" onChange={() => setNewsletterError("")}/>
                                        </div>
                                        <div className="quick-form-item">
                                            <button type="submit" className="btn btn-bordered btn-large btn-current">S'inscrire</button>
                                        </div>
                                    </form>
                                    <Modal data={newsletterModal} setter={setNewsletterModal}>

                                    </Modal>
                                </div>
                            </div>
                            <div className="shape-abs shape-medium shape-triangle-lower-right shape-corner-bottom-right shape-great"></div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Home